//
//
//
//

export default {
  mounted() {
    location.href = "/hello.html";
  },
};

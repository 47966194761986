import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
/*
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
*/
import axios from 'axios';
import qs from 'qs';
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
import { multiply, bignumber, floor, divide } from 'mathjs';
Vue.prototype.$math = { multiply, bignumber, floor, divide };
import Vant from 'vant';
import 'vant/lib/index.css';
import vcolorpicker from 'vcolorpicker';
Vue.use(vcolorpicker);
import { fabric } from "fabric";
Vue.prototype.$fabric = fabric;
import signature from 'signature_pad';
Vue.prototype.$signature = signature;
const { Conflux, Drip, format } = require('js-conflux-sdk');
Vue.prototype.$conflux = Conflux;
Vue.prototype.$drip = Drip;
Vue.prototype.$format = format;
Vue.use(Vant);
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        nft_url_t: "https://www.artii.top/temp_pic_pro/",
        nft_url: "https://www.artii.top/nftpic/",
        staking: '',
        address: '',
        balance: '',
        name: '',
        art: '',
        earned: '',
        mybalance: '',
        profit: '',
        domain: "https://v2.artii.cc",
        //api: "http://test.artii.top/api/v1",
        //api2: "http://test.artii.top/api/v2",
        api1: "https://www.artii.top/api/v1",
        //api: "http://127.0.0.1:7026/api/v2",
        api: "https://v2.artii.cc/api/v2",
    },
    getters: {
        nft_url: (state) => {
            return state.nft_url;
        },
        nft_url_t: (state) => {
            return state.nft_url_t;
        },
    },
    mutations: {},
    actions: {},
    modules: {}
});

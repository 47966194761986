//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      conflux: null,
      showvip: false,
      active2: 0,
      number: 0, //购买VIP
      levelname: "Normal",
      levelstar: 0,
      viplist: [],
      art: 0,

      nftname: "",

      active: 0,
      show: false,
      id: 0,
      tid: 0, // 售卖 tokenid
      currdesc: "",
      amount: "",
      amount2: "",
      price: "",
      price2: "", //拍卖起价
      price3: "", //拍卖加价幅度
      endtime: "", // 拍卖结束时间
      zhuzaozhe: "",
      no: "",
      staking: "",
      currtitle: "",
      currbizhi: "",
      thumb: "",
      releaseitem: {},
    };
  },
  activated() {
    this.init();
  },
  mounted() {
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.$axios.defaults.headers.common.Authorization = localStorage.getItem("_address");

    // 来客访问时的临时身份(时间戳)
    const timeuid = localStorage.getItem("_timeuid")
      ? localStorage.getItem("_timeuid")
      : localStorage.setItem("_timeuid", new Date().getTime());
    this.$axios.defaults.headers.common["T-TOKEN"] = timeuid;

    window.getAddress = this.getAddress;
    // V1 版本授权
    window.approvalpai = this.approvalpai;
    window.approvalshop = this.approvalshop;
    window.approvalvip = this.approvalvip;
    this.init();
  },
  methods: {
    // 账户登录
    async getAddress() {
      this.conflux = new this.$conflux({
        url: window.RPC_URL,
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;

      let accounts = null;
      let _address = "";
      try {
        accounts = await conflux.request({ method: "cfx_requestAccounts" });
        _address = accounts + "";
        window._address = _address;
      } catch (error) {
        console.log("error");
      }
      if (_address === "") {
        try {
          accounts = await window.conflux.enable();
        } catch (error) {}
        // 新版本手机
        if (window.isArrayFn(accounts)) {
          _address = accounts[0];
        } else {
          const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
          _address = accounts2.result[0];
        }
        window._address = _address;
      }
      localStorage.setItem("_address", _address);

      try {
        document.getElementById("accname").innerHTML =
          '<div class="qb_btn">' +
          _address.substring(0, 6) +
          "..." +
          _address.substring(45, _address.lenght) +
          "</div>";
      } catch (error) {}

      return _address;
    },

    // 显示VIP
    vipshow() {
      this.showvip = true;
      this.vip();
    },

    // VIP显示时 去除最高位数字
    removeHightBitOfNumber(value) {
      const stringValue = "" + value;
      return parseInt(stringValue.substring(1, stringValue.length));
    },

    async init() {
      let _address = await window.getAddress();

      this.$axios.defaults.headers.common.Authorization = _address;

      setTimeout(() => {
        this.vip();
      }, 1000);

      // 服务器中获取用户信息
      /*
      const timeuid = localStorage.getItem("_timeuid");
      this.$axios
        .get(this.$store.state.api + "/account?tuid=" + timeuid)
        .then((response) => {
          localStorage.setItem("nft_acc_id", response.data);
        });
      */

      this.conflux = new this.$conflux({
        url: window.RPC_URL,
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;
      // 链接区块链数据
      /*const ArtSTAKE = this.conflux.Contract({
        abi: STAKE_ABI,
        address: STAKE_ADDRESS,
      });*/

      if (!window.ArtCoin) {
        const ArtCoin = this.conflux.Contract({
          abi: CONTRACT_ABI,
          address: CONTRACT_ADDRESS,
        });
        window.ArtCoin = ArtCoin;
      }

      if (!window.ArtNFT) {
        const ArtNFT = await this.conflux.Contract({
          abi: ArtNFT_ABI,
          address: ArtNFT_ADDRESS,
        });
        window.ArtNFT = ArtNFT;
      }

      if (!window.MainArtPool) {
        const MainArtPool = this.conflux.Contract({
          abi: MainArtPool_ABI,
          address: MainArtPool_ADDRESS,
        });
        window.MainArtPool = MainArtPool;
      }

      if (!window.SHOP) {
        const SHOP = await this.conflux.Contract({
          abi: SHOP_ABI,
          address: SHOP_ADDRESS,
        });
        window.SHOP = SHOP;
      }

      if (!window.SHOPPAI) {
        const SHOPPAI = await this.conflux.Contract({
          abi: PAI_ABI,
          address: PAI_ADDRESS,
        });
        window.SHOPPAI = SHOPPAI;
      }

      const coin = await window.ArtCoin.balanceOf(_address);
      this.art = parseFloat(this.$drip(coin).toCFX()).toFixed(4);

      console.log("-------------------------------------------------------------------");

      const accountbalance = await this.conflux.getBalance(_address);
      const tbalance = this.$drip(accountbalance).toCFX();
      const mybalance = parseFloat(tbalance).toFixed(4);

      this.$store.balance = mybalance;
      try {
        window.setMybalance(mybalance);
      } catch (error) {}

      // 授权: _address[用户] -> CONTRACT[合约]
      window.isappr = await ArtNFT.isApprovedForAll(_address, SHOP_ADDRESS);
      window.isappr2 = await ArtNFT.isApprovedForAll(_address, PAI_ADDRESS);
      window.isappr3 = await ArtNFT.isApprovedForAll(_address, VIP_ADDRESS);
    },

    //调用合约查询 VIP 级别
    async vip() {
      const _address = localStorage.getItem("_address");

      const ArtVIP = await this.conflux.Contract({
        abi: VIP_ABI,
        address: VIP_ADDRESS,
      });
      window.ArtVIP = ArtVIP;

      const tt = await ArtVIP.tokensOf(_address);
      let level = 100000000000000000000;

      this.viplist = [];
      tt.forEach((element) => {
        const p = this.$drip.fromCFX(element);
        const tmp = this.$drip(p).toCFX();

        let tmpname = "";
        let tlevelstar = 0;
        if (tmp > 50000000000) {
          tmpname = "MiniVIP";
          tlevelstar = 0.5;
        } else if (tmp > 40000000000) {
          tmpname = "VIP";
          tlevelstar = 1;
        } else if (tmp > 30000000000) {
          tmpname = "SVIP";
          tlevelstar = 2;
        } else if (tmp > 20000000000) {
          tmpname = "SSVIP";
          tlevelstar = 3;
        } else if (tmp > 10000000000) {
          tmpname = "SSSVIP";
          tlevelstar = 4;
        } else if (tmp < 10000000000) {
          tmpname = "XVIP";
          tlevelstar = 5;
        }

        this.viplist.push({
          i: tlevelstar,
          url: this.$store.getters.nft_url,
          tokenid: +tmp,
          name: tmpname,
          pic: tlevelstar,
          id: this.removeHightBitOfNumber(tmp),
        });

        if (tmp < level) {
          level = tmp;
        }
      });

      // 最高级别

      let levelname = "Normal";
      let levelstar = 0;
      if (level > 50000000000) {
        levelname = "MiniVIP";
        levelstar = 0.5;
      } else if (level > 40000000000) {
        levelname = "VIP";
        levelstar = 1;
      } else if (level > 30000000000) {
        levelname = "SVIP";
        levelstar = 2;
      } else if (level > 20000000000) {
        levelname = "SSVIP";
        levelstar = 3;
      } else if (level > 10000000000) {
        levelname = "SSSVIP";
        levelstar = 4;
      } else if (level < 10000000000) {
        levelname = "XVIP";
        levelstar = 5;
      }
      if (this.viplist.length > 0) {
        this.levelname = levelname;
        this.levelstar = levelstar;
      }
    },
    // 购买 VIP
    async buyVIP() {
      const _address = await window.getAddress();

      this.$axios.defaults.headers.common["A-ADDRESS"] = _address; // AJAX 配置 Authorization
      const is = await window.ArtCoin.isOperatorFor(VIP_ADDRESS, _address);
      if (!is) {
        await window.ArtCoin.authorizeOperator(VIP_ADDRESS)
          .sendTransaction({
            from: _address,
          })
          .executed();
      }

      let price = 1;
      switch (this.number) {
        case "1":
          price = 10;
          break;
        case "2":
          price = 100;
          break;
        case "3":
          price = 1000;
          break;
        case "4":
          price = 10000;
          break;
        case "5":
          price = 100000;
          break;
        default:
          break;
      }

      if (this.art < price) {
        this.$toast.fail("ART-Token not enough");
        return;
      }

      this.$axios
        .post(this.$store.state.api + "/getvip", {
          price: price,
        })
        .then(async (response) => {
          const data = response.data;

          if (data) {
            this.$toast.loading({
              message: "processing...",
              duration: 0,
            });

            try {
              const priceT = this.$drip.fromCFX(price);

              const ArtVIP = await this.conflux.Contract({
                abi: VIP_ABI,
                address: VIP_ADDRESS,
              });

              let collateral = await ArtVIP.mint(
                "vip_" + data,
                priceT
              ).estimateGasAndCollateral({
                from: _address,
              });

              const rest = await ArtVIP.mint("vip_" + data, priceT)
                .sendTransaction({
                  gas: collateral.gasLimit,
                  storageLimit: collateral.storageCollateralized,
                  value: 0,
                  from: _address,
                })
                .executed();

              const tokens = await ArtVIP.tokensOf(_address);
              const tokenid = tokens.pop();
              console.log(tokenid);
              this.$axios
                .put(this.$store.state.api + "/setvip", {
                  id: data,
                  tokenid: tokenid,
                })
                .then(async (response) => {
                  this.$toast.clear();
                });
              setTimeout(() => {
                this.$toast.clear();
                this.init();
              }, 3000);
            } catch (error) {
              this.$toast.clear();
            }
          }
        });
    },

    async approvalshop() {
      if (!window.isappr) {
        // 没有授权
        const collateral1 = await ArtNFT.setApprovalForAll(
          SHOP_ADDRESS,
          true
        ).estimateGasAndCollateral({ from: _address });
        const isApproved = await ArtNFT.setApprovalForAll(SHOP_ADDRESS, true)
          .sendTransaction({
            from: _address,
            gas: collateral1.gasLimit,
            storageLimit: collateral1.storageCollateralized,
          })
          .executed();

        window.isappr = true;
        //console.log("授权结果：" + JSON.stringify(isApproved));
      }
    },

    async approvalpai() {
      if (!window.isappr2) {
        // 没有授权
        const collateral1 = await ArtNFT.setApprovalForAll(
          PAI_ADDRESS,
          true
        ).estimateGasAndCollateral({ from: _address });
        const isApproved = await ArtNFT.setApprovalForAll(PAI_ADDRESS, true)
          .sendTransaction({
            from: _address,
            gas: collateral1.gasLimit,
            storageLimit: collateral1.storageCollateralized,
          })
          .executed();
        window.isappr2 = true;
        //console.log("授权结果：" + JSON.stringify(isApproved));
      }
    },

    async approvalvip() {
      if (!window.isappr3) {
        const collateral1 = await ArtNFT.setApprovalForAll(
          VIP_ADDRESS,
          true
        ).estimateGasAndCollateral({ from: _address });
        const isApproved = await ArtNFT.setApprovalForAll(VIP_ADDRESS, true)
          .sendTransaction({
            from: _address,
            gas: collateral1.gasLimit,
            storageLimit: collateral1.storageCollateralized,
          })
          .executed();

        window.isappr3 = true;
      }
    },

    // 调起售卖 填写价格
    async release(item) {
      this.id = +item.id;
      this.tid = +item.tokenid;
      this.thumb = item.url + "vip/" + item.tokenid + ".png";
      this.currtitle = item.title;
      this.currbizhi = item.price;
      this.no = item.tokenid;
      this.releaseitem = item;
      this.currdesc = item.des;
      this.nftname = item.name;

      this.show = true;
      return;
      const _address = await window.getAddress();
      const ArtNFT_V2 = await this.conflux.Contract({
        abi: ArtNft_V2_ABI,
        address: ArtNft_V2_ADDRESS,
      });

      //const price = await ArtNFT_V2.userMintPrices(+this.tid); // 铸造时候的值 永不变
      //const p1 = Drip(price).toCFX();

      const amount = await ArtNFT_V2.balanceOf(_address, +this.tid); // 我剩余的 块
      this.amount = +amount;
      this.amount2 = +amount;

      setTimeout(() => {
        this.active = "0";
      }, 100);
    },
    releaseit() {},
    auction() {},
    async burnit() {
      this.$dialog
        .confirm({
          title: "Do you need to burn this NFT? ",
          message: "This operation is irreversible, please operate with caution!",
          confirmButtonText: "Yes",
          confirmButtonColor: "#000",
          cancelButtonText: "No",
          cancelButtonColor: "red",
          zIndex: 50000,
        })
        .then(async () => {
          // on confirm

          try {
            const _address = await window.getAddress();

            const ArtVIP = await this.conflux.Contract({
              abi: VIP_ABI,
              address: VIP_ADDRESS,
            });

            this.$toast.loading({
              message: "processing...",
              duration: 0,
            });

            //const amount = await ArtNft_V2.balanceOf(_address, +this.tid); // 铸造中的 amount

            this.show = false;

            let collateral = await ArtVIP.burn(
              _address,
              +this.tid
            ).estimateGasAndCollateral({ value: 0, from: _address });
            //console.log(collateral);

            const burn = await ArtVIP.burn(_address, +this.tid)
              .sendTransaction({
                gas: collateral.gasLimit,
                storageLimit: collateral.storageCollateralized,
                from: _address,
              })
              .executed();

            this.$toast.clear();

            this.vip();
          } catch (error) {
            console.log(error);
            //console.log(error.message.indexOf("Not owner")); // 已经属于别人
            //this.$toast.fail("已经拍卖成功，等待用户取回");
            this.$toast.clear();
            this.show = false;
          }
          this.show = false;
        })
        .catch(() => {
          // on cancel
        });
    },
    closes1() {
      this.show = false;
    },

    cfxmy(amount) {
      var price = amount;
      price = parseInt(price / 1e14);
      price = (price / 10000).toFixed(4);

      return price;
    },
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/board',
        name: 'Board',
        component: () => import('../views/Board.vue')
    },
    {
        path: '/painting',
        name: 'Painting',
        component: () => import('../views/painting/Painting.vue')
    },
    {
        path: '/pro/painting',
        name: 'Propainting',
        component: () => import('../views/Propainting.vue')
    },
    {
        path: '/painting2',
        name: 'Painting2',
        component: () => import('../views/painting/Painting2.vue')
    },
    {
        path: '/my',
        name: 'My',
        component: () => import('../views/my/My.vue')
    },
    {
        path: '/my_m',
        name: 'MyMobile',
        component: () => import('../views/my/MyMobile.vue')
    },
    {
        path: '/sell',
        name: 'Sell',
        component: () => import('../views/shop/Sell.vue')
    },
    {
        path: '/sellall',
        name: 'SellAll',
        component: () => import('../views/shop/SellAll.vue')
    },
    {
        path: '/pai/all',
        name: 'PaiAll',
        component: () => import('../views/shop/PaiAll.vue')
    },
    {
        path: '/paiok/all',
        name: 'PaiOkAll',
        component: () => import('../views/PaiOkAll.vue')
    },
    {
        path: '/pai2/all',
        name: 'Pai2All',
        component: () => import('../views/shop/Pai2All.vue')
    },
    {
        path: '/paiok2/all',
        name: 'Pai2OkAll',
        component: () => import('../views/shop/Pai2okAll.vue')
    },
    {
        path: '/sell_m',
        name: 'SellMobile',
        component: () => import('../views/shop/SellMobile.vue')
    },
    {
        path: '/sell2all',
        name: 'Sell2All',
        component: () => import('../views/shop/Sell2All.vue')
    },
    {
        path: '/pool',
        name: 'Pool',
        component: () => import('../views/pool/Pool.vue')
    },
    {
        path: '/pool_m',
        name: 'PoolMobile',
        component: () => import('../views/pool/PoolMobile.vue')
    },
    {
        path: '/utilities',
        name: 'Utilities',
        component: () => import('../views/utilities/Utilities.vue')
    },
    {
        path: '/utilities_m',
        name: 'UtilitiesMobile',
        component: () => import('../views/utilities/UtilitiesMobile.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router;
